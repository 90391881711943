import React from "react";

const Collaboration = () => {
  const emailAddress = "contact@mohsin-idrees.com";
  const upworkProfileUrl =
    "https://www.upwork.com/freelancers/~01990863ba7110b5a9"; // Replace with your actual Upwork profile URL

  const handleEmailButtonClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <section className="flex flex-col justify-start py-[80px] items-center w-full max-w-[1480px] gap-4 p-4 mx-auto">
      <h2 className="font-[EurostileBold] text-[25px] text-[#151c3a] font-semibold text-center leading-[36px]">
        Ready to Work Together?
      </h2>
      <p className="font-[EuropaRegular] text-[18px] text-center leading-[25px] lg:leading-[28px] tracking-wide text-[#5c5c5c] max-w-[650px]">
        I'm always open to new opportunities and partnerships. If you have a
        project in mind or would like to discuss potential collaborations, feel
        free to get in touch.
      </p>
      <div className="flex gap-4 flex-col sm:flex-row mt-4 sm:mt-0">
        <div
          className="font-[EuropaRegular] flex justify-center items-center text-[18px] text-[#6e08f3] group border-2 max-w-[300px] gap-2 border-[#6e08f3] px-3 py-2 rounded-3xl transition-all ease-in-out duration-500 hover:px-4 cursor-pointer hover:bg-[#6e08f3] hover:text-white"
          onClick={handleEmailButtonClick}
        >
          <svg
            className="svg-inline--fa fa-message-lines w-[20px] fill-[#6e08f3] h-[20px] group-hover:fill-white"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="message-lines"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            data-fa-i2svg=""
          >
            <path d="M208 416c0-26.5-21.5-48-48-48H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H448c8.8 0 16 7.2 16 16V352c0 8.8-7.2 16-16 16H309.3c-10.4 0-20.5 3.4-28.8 9.6L208 432V416zm-.2 76.2l.2-.2 101.3-76H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h48 48v48 4 .3 6.4V496c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L202.7 496l5.1-3.8zM152 144c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"></path>
          </svg>{" "}
          Start a Conversation
        </div>
        <a
          href={upworkProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="font-[EuropaRegular] flex justify-center items-center text-[18px] text-[#6e08f3] group border-2 max-w-[300px] gap-2 border-[#6e08f3] px-3 py-2 rounded-3xl transition-all ease-in-out duration-500 hover:px-4 cursor-pointer hover:bg-[#6e08f3] hover:text-white"
        >
          <svg
            width="30px"
            viewBox="0 -20 200 200"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
          >
            <g>
              <path
                d="M140.106589,96.6278184 C131.785652,96.6278184 123.989458,93.1045388 116.905417,87.3698389 L118.629575,79.2737921 L118.704539,78.9739385 C120.241288,70.3531479 125.113909,55.8852123 140.106589,55.8852123 C151.351098,55.8852123 160.496633,65.0307467 160.496633,76.2752562 C160.459151,87.482284 151.313616,96.6278184 140.106589,96.6278184 L140.106589,96.6278184 Z M140.106589,35.2327965 C120.953441,35.2327965 106.110688,47.6767204 100.076135,68.1417277 C90.8556369,54.310981 83.884041,37.7065886 79.7985359,23.7259151 L59.1836018,23.7259151 L59.1836018,77.3622255 C59.1836018,87.9320644 50.5628111,96.5528551 39.9929722,96.5528551 C29.4231332,96.5528551 20.8023426,87.9320644 20.8023426,77.3622255 L20.8023426,23.7259151 L0.187408492,23.7259151 L0.187408492,77.3622255 C0.112445095,99.3265007 17.9912152,117.355198 39.9554905,117.355198 C61.9197657,117.355198 79.7985359,99.3265007 79.7985359,77.3622255 L79.7985359,68.3666179 C83.8090776,76.7250366 88.7191801,85.1584187 94.6787701,92.6547584 L82.0474378,152.025769 L103.149634,152.025769 L112.295168,108.959297 C120.316252,114.09429 129.53675,117.317716 140.106589,117.317716 C162.708053,117.317716 181.111567,98.801757 181.111567,76.2002928 C181.111567,53.6363104 162.708053,35.2327965 140.106589,35.2327965 L140.106589,35.2327965 Z"
                fill="#6FDA44"
              ></path>
            </g>
          </svg>{" "}
          Hire me on Upwork
        </a>
      </div>
    </section>
  );
};

export default Collaboration;
