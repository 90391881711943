import React from "react";
import { Link } from "react-router-dom";
import { Svg1, Svg2, Svg3 } from "./svg";

const Footer = () => {
  const socialLinks = [
    {
      img: "assets/icons/github-142-svgrepo-com.svg",
      link: "https://github.com/mohsinidrees2817",
      alt: "GitHub",
    },
    {
      img: "assets/icons/gitlab-svgrepo-comm.svg",
      link: "https://gitlab.com/mohsinidrees2817",
      alt: "GitLab",
    },
    {
      img: "assets/icons/linkedin-svgrepo-com.svg",
      link: "https://www.linkedin.com/in/mohsin-idrees-71160a233/",
      alt: "LinkedIn",
    },
    {
      img: "assets/icons/twitter-svgrepo-com.svg",
      link: "https://twitter.com/Mohsin_FED",
      alt: "Twitter",
    },
    {
      img: "assets/icons/behance-svgrepo-com.svg",
      link: "https://www.behance.net/Mohsin_Abbasi1",
      alt: "Behance",
    },
  ];

  return (
    <footer className="bg-[#6e08f3]">
      <div className="bg-[#6e08f3] flex flex-col justify-center py-[70px] items-center w-full max-w-[1480px] gap-8 lg:gap-8 p-4 mx-auto">
        <img src="assets/mohsin.svg" alt="Mohsin's Logo" width={50} />
        <p className="font-[EuropaRegular] text-[18px] text-center leading-[25px] text-[#b988fb] max-w-[300px] font-light">
          Living, learning, & leveling up one day at a time.
        </p>
        <div className="flex justify-center items-center gap-2 md:gap-8">
          {socialLinks.map((link, index) => (
            <a
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <div className="border-2 p-2 lg:p-2 cursor-pointer rounded-full hover:bg-black/50 border-[#b988fb]">
                <img src={link.img} alt={link.alt} width={20} height={20} />
              </div>
            </a>
          ))}
        </div>
        <p className="font-[EuropaRegular] text-[18px] text-center leading-[15px] text-[#b988fb] font-light">
          Handcrafted by me &copy; 2024
        </p>
      </div>
    </footer>
  );
};

export default Footer;
