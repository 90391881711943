import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Header from "../components/Header";
import { Link } from "react-router-dom";

const Chatbot = () => {
  const [showChat, setShowChat] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);

  const handleChatOpen = () => setShowChat(true);
  const handleChatClose = () => setShowChat(false);

  const handleSendMessage = () => {
    // Here, you can add logic to handle user messages and dummy responses
    setChatHistory([...chatHistory, { user: true, message: userMessage }]);
    setUserMessage(""); // Clear the input field
    // Add logic for the dummy response (for demonstration purposes)
    setChatHistory([
      ...chatHistory,
      { user: false, message: "Dummy response" },
    ]);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <Header />

      <div
        className="font-[EuropaRegular] flex justify-center items-center text-[18px] text-[#6e08f3] group border-2 max-w-[300px] gap-2 border-[#6e08f3] px-3 py-2 rounded-3xl transition-all ease-in-out duration-500 hover:px-4 cursor-pointer hover:bg-[#6e08f3] hover:text-white my-8"
        onClick={handleChatOpen}
      >
        <svg
          class="svg-inline--fa fa-message-lines w-[20px] fill-[#6e08f3] h-[20px] group-hover:fill-white"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="message-lines"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path d="M208 416c0-26.5-21.5-48-48-48H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H448c8.8 0 16 7.2 16 16V352c0 8.8-7.2 16-16 16H309.3c-10.4 0-20.5 3.4-28.8 9.6L208 432V416zm-.2 76.2l.2-.2 101.3-76H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h48 48v48 4 .3 6.4V496c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L202.7 496l5.1-3.8zM152 144c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"></path>
        </svg>{" "}
        Chat with PDF
      </div>

      <Modal show={showChat} onHide={handleChatClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chat with PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="chat-history">
            {chatHistory.map((item, index) => (
              <div
                key={index}
                className={item.user ? "user-message" : "dummy-message"}
              >
                {item.message}
              </div>
            ))}
          </div>
          <Form.Control
            type="text"
            placeholder="Type your message..."
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleChatClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendMessage}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Chatbot;
