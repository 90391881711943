import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Herosection from "../components/Herosection";
import Aboutsection from "../components/Aboutsection";
import Recentwork from "../components/Recentwork";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import Collaboration from "../components/Collaboration";
import Loader from "../components/Loader";
import Header from "../components/Header";
// import Clients from "../components/Companies";
const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay (you can replace this with actual loading logic)
    const delay = setTimeout(() => {
      setLoading(false);
      clearTimeout(delay);
    }, 2000); // Adjust the delay as needed
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Mohsin Idrees - Full Stack Developer, AI Chatbots & AWS Services
          Expert
        </title>
        <meta
          name="description"
          content="Crafting elegantly simple designs and code with passion. Mohsin Idrees - Full Stack Developer, AI Chatbots & AWS Services Expert
"
        />
        <meta
          name="keywords"
          content="Mohsin Idrees, Designer, Frontend Developer, AI Chatbots, AWS Services, Web Development, UI/UX Design"
        />
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="max-w-[1480px] mx-auto overflow-hidden">
            <Header />
            <Herosection setLoading={setLoading} />
          </div>
          <Aboutsection />
          <Recentwork />
          <Testimonials />
          <Collaboration />
          {/* <Clients /> */}
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;
