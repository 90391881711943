import React from 'react';
import Slider from './Slider';

const Testimonials = () => {
  return (
    <section className="flex flex-col justify-center items-center w-full max-w-[1480px] gap-4 p-4 min-h-[550px] mx-auto">
      <h2 className="font-[EurostileBold] text-[35px] font-bold text-center text-[#141c3a] leading-[45px]">
        Client Testimonials
      </h2>
      <p className="font-[EuropaRegular] text-[20px] text-center leading-[36px] text-[#5c5c5c]">
        Discover what people I've worked with have said about their experience...
      </p>

      <Slider />
    </section>
  );
};

export default Testimonials;
