import React from "react";
import { useParams } from "react-router-dom";

const caseStudyData = [
  {
    id: 1,
    title: "Techtribe",
    description:
      "A comprehensive development and design project for a tech company.",
    content:
      "Detailed description of Techtribe project, including challenges, solutions, and outcomes.",
    imageUrl: "/assets/techtribe-full.webp",
    link: "https://techtribe.vercel.app/",
  },
  {
    id: 2,
    title: "Salebag",
    description: "An innovative marketplace solution.",
    content:
      "Detailed description of Salebag project, including its features, design, and user impact.",
    imageUrl: "/assets/salebag-full.webp",
    link: "https://salebag-2-0.vercel.app/",
  },
  {
    id: 3,
    title: "Info Homes",
    description: "A data-driven platform for real estate insights.",
    content:
      "Detailed description of Info Homes project, focusing on its data analytics capabilities and user experience.",
    imageUrl: "/assets/infohomes-full.webp",
    link: "https://infohomes.vercel.app/",
  },
  // Add more case studies as needed
];

const CaseStudy = () => {
  const { id } = useParams();
  const caseStudy = caseStudyData.find((item) => item.id === parseInt(id, 10));

  if (!caseStudy) {
    return <div>Case study not found.</div>;
  }

  return (
    <div className="max-w-[1480px] mx-auto p-8">
      <h1 className="font-[EurostileBold] text-[35px] font-bold text-[#141c3a]">
        {caseStudy.title}
      </h1>
      <p className="font-[EuropaRegular] text-[20px] text-[#5c5c5c] mb-4">
        {caseStudy.description}
      </p>
      <img
        src={caseStudy.imageUrl}
        alt={caseStudy.title}
        className="w-full h-auto mb-4 object-cover object-center"
      />
      <p className="font-[EuropaRegular] text-[18px] text-[#333] mb-4">
        {caseStudy.content}
      </p>
      <a
        href={caseStudy.link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#6e08f3] text-lg font-semibold underline"
      >
        Visit Project Website
      </a>
    </div>
  );
};

export default CaseStudy;
