import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Home from "./Pages/Home";
import Chatbot from "./Pages/Chatbot";
import CaseStudy from "./components/Casestudy"; // Your case study component

function App() {
  const socialSharingImage =
    "https://mohsin-idrees.com/assets/mohsin-idrees-logo.png";

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Mohsin Idrees</title>
        <meta
          name="description"
          content="Full Stack Developer, AI Chatbots & AWS Services Expert
"
        />
        <meta
          name="keywords"
          content="Mohsin, Idrees, Abbasi, designer, aws cognito, amazon cognito, lambda, dynamodb. mongodb, react, next, tailwind CSS, CSS, HTML, CSS,  javascript, S3"
        />

        {/* Open Graph (OG) Tags */}
        <meta property="og:title" content="Mohsin Idrees" />
        <meta
          property="og:description"
          content="Full Stack Developer, AI Chatbots & AWS Services Expert
"
        />
        <meta property="og:image" content={socialSharingImage} />
        <meta property="og:url" content="https://mohsin-idrees.com/" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Mohsin Idrees" />
        <meta
          name="twitter:description"
          content="Full Stack Developer, AI Chatbots & AWS Services Expert
"
        />
        <meta name="twitter:image" content={socialSharingImage} />

        {/* WhatsApp Tags */}
        <meta property="og:site_name" content="Mohsin Idrees" />
        <meta property="og:type" content="website" />

        {/* Instagram Tags */}
        <meta property="og:type" content="website" />
      </Helmet>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pakstudychatbot" element={<Chatbot />} />
          <Route path="/case-study/:id" element={<CaseStudy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
