import React, { useState } from "react";
import { FaCode, FaAws, FaRobot } from "react-icons/fa";

const Aboutsection = () => {
  const [visibleToolsFullStack, setVisibleToolsFullStack] = useState(5);
  const [visibleToolsChatbots, setVisibleToolsChatbots] = useState(5);
  const [visibleToolsAWService, setVisibleToolsAWService] = useState(5);

  const handleShowMoreFullStack = () => {
    setVisibleToolsFullStack((prev) => prev + 15);
  };

  const handleShowMoreAWService = () => {
    setVisibleToolsAWService((prev) => prev + 15);
  };

  const handleShowMoreChatbots = () => {
    setVisibleToolsChatbots((prev) => prev + 15);
  };

  const sections = [
    {
      title: "Full Stack Developer",
      content:
        "I enjoy coding from scratch and bringing ideas to life in the browser and beyond.",
      skillstitle: "Languages & Frameworks:",
      skills:
        "HTML, CSS, Tailwind CSS, Bootstrap, JavaScript,Typescript, React, Next.js, Node.js, Express.js Python, FastAPI",
      tootstitle: "Development Tools:",
      tools: [
        "Visual Studio Code",
        "Postman",
        "Chrome DevTools",
        "Docker",
        "Kubernetes",
        "Git",
        "GitHub",
        "GitLab",
        "MongoDB",
        "Azure db",
        "MySQL",
        "Serverless Framework",
        "Netlify",
        "Heroku",
        "Vercel",
        "CI/CD Pipelines",
      ],
      toolsicon: <FaCode className="w-40 font-bold text-center" />,
    },
    {
      title: "AWS Services",
      content:
        "Empowering projects with secure authentication, swift data access, and effortless Lambda.",
      skillstitle: "Experience I have:",
      skills:
        "User authentication, database management, serverless computing, infrastructure as code.",
      tootstitle: "Services I use:",
      tools: [
        "Amazon Cognito",
        "AWS Lambda",
        "Amazon S3",
        "DynamoDB",
        "Amazon Q",
        "Amazon CloudFormation",
        "Amazon EC2",
        "Amazon ALB",
        "IAM Identity Center",
        "AWS API Gateway",
        "Amazon Q Bussiness",
      ],
      toolsicon: <FaAws className="w-40 font-bold text-center" />,
    },
    {
      title: "AI Chatbots",
      content:
        "Developing intelligent chatbots that enhance user interactions and automate processes.",
      skillstitle: "Technologies & Skills:",
      skills:
        "Self-trained data models, RAG, Conversational AI, Rasa, DB query-based search chatbots, API Integrated bots.",
      tootstitle: "Tools & Platforms:",
      tools: [
        "LangChain",
        "Rasa",
        "Pinecone",
        "chroma.ai",
        "Chatbot frameworks",
        "Microsoft Bot Framework",
        "IBM Watson",
        "Botpress",
        "ChatGPT",
        "Wit.ai",
        "Amazon Lex",
        "TARS",
        "Flow XO",
        "Kuki",
      ],
      toolsicon: <FaRobot className="w-40 font-bold text-center" />,
    },
  ];

  return (
    <>
      {/* About info */}
      <section className="flex justify-center items-center w-full max-w-[860px] mx-auto">
        <img src="assets/hero-devices.svg" alt="devices" className="px-4" />
      </section>
      <section className="bg-[#6e08f3] w-full">
        <div className="flex flex-col justify-start py-[100px] items-center w-full max-w-[1480px] gap-4 p-4 min-h-[600px] mx-auto">
          <h1 className="font-[EurostileBold] text-[30px] text-white font-bold text-center leading-[36px]">
            Hi, I&apos;m Mohsin, Great to connect with you.
          </h1>
          <p className="font-[EuropaRegular] text-[18px] text-center leading-[25px] lg:leading-[28px] tracking-wide text-white max-w-[650px]">
            I am a top-rated seller, With 4+ years of experience in full
            stack development, I&apos;ve worked with agencies and collaborated
            with talented teams to create digital products for businesses and
            consumers. My expertise includes AWS services and AI chatbot
            Development. I&apos;m committed to delivering high-quality work and
            ensuring client satisfaction. I&apos;m confident in my skills, and
            always focused on improving my expertise.
          </p>
        </div>
      </section>

      {/* Skills info */}
      <section className="max-w-[1480px] mx-auto lg:px-16">
        <div className="flex justify-between border items-start gap-16 flex-col md:flex-row px-[50px] py-[50px] mx-4 rounded-lg -mt-20 sm:-mt-40 bg-white ">
          {sections.map((section, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center gap-4 "
            >
              <div className="bg-[#5be9b9] w-[70px] h-[70px] p-2 rounded-full flex justify-center items-center">
                {section.toolsicon}
              </div>

              <h2 className="font-[EurostileBold] text-[24px] font-bold text-center">
                {section.title}
              </h2>
              <p className="text-center font-[EurostileBold] ">
                {section.content}
              </p>
              <div className="flex flex-col gap-2 py-6">
                <p className="text-center text-[#6e08f3] font-[EurostileBold]">
                  {section.skillstitle}
                </p>
                <p className="text-center font-[EurostileBold]">
                  {section.skills}
                </p>
              </div>
              <div className="flex flex-col gap-2 py-6">
                <p className="text-center text-[#6e08f3] font-[EurostileBold]">
                  {section.tootstitle}
                </p>
                {section.tools
                  .slice(
                    0,
                    section.title === "Full Stack Developer"
                      ? visibleToolsFullStack
                      : section.title === "AWS Services"
                      ? visibleToolsAWService
                      : visibleToolsChatbots
                  )
                  .map((tool, index) => (
                    <p className="text-center font-[EurostileBold]" key={index}>
                      {tool}
                    </p>
                  ))}
                {section.tools.length >
                  (section.title === "Full Stack Developer"
                    ? visibleToolsFullStack
                    : section.title === "AWS Services"
                    ? visibleToolsAWService
                    : visibleToolsChatbots) && (
                  <button
                    className="text-[#239b73] mt-4 cursor-pointer text-sm "
                    onClick={
                      section.title === "Full Stack Developer"
                        ? handleShowMoreFullStack
                        : section.title === "AWS Services"
                        ? handleShowMoreAWService
                        : handleShowMoreChatbots
                    }
                  >
                    Show More...
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Aboutsection;
