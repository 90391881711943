// Import Swiper React components
import {
  EffectFade,
  EffectCoverflow,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { useState } from "react";

//   SwiperCore.use([Autoplay]);

export default () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const Slides = [
    {
      image: "/assets/reviews/r7.png",
    },
    {
      image: "/assets/reviews/r8.jpeg",
    },
    {
      image: "/assets/reviews/r9.jpeg",
    },
    {
      image: "/assets/reviews/r6.webp",
    },
    {
      image: "/assets/reviews/r2.webp",
    },
    {
      image: "/assets/reviews/r1.webp",
    },
    {
      image: "/assets/reviews/r3.webp",
    },
    {
      image: "/assets/reviews/r4.webp",
    },
    {
      image: "/assets/reviews/r5.webp",
    },
  ];
  return (
    <div className="container mx-auto">
      <Swiper
        //   autoplay={true}
        autoplay={{ delay: 3000 }}
        delay={1000}
        effect="fade"
        grabCursor={true}
        // loop={true}
        pagination={{ el: "#custom-pagination", clickable: true }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          450: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.realIndex + 1);
        }}
        onSwiper={(swiper) => {
          setSwiperInstance(swiper);
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className=" w-full  "
      >
        {Slides.map((image, index) => {
          return (
            <SwiperSlide className="lg:mr-[30px]  w-[200px]    lg:max-w-[600px]   ">
              <img
                src={image.image}
                alt="slide_image"
                className="rounded-3xl  lg:w-full pt-4 "
              />
            </SwiperSlide>
          );
        })}

        <div className="flex  justify-center items-center px-4 md:px-[40px] gap-[20px] lg:gap-[70px] xl:gap-[105px] pt-[38px] mx-aut0">
          {/* <p className=" text-[#ffffff] whitespace-nowrap leading-5 text-[14px]">
              Showing {currentSlide}/{Slides.length}
            </p> */}
          <div className="flex gap-2 lg:gap-4 xl:gap-[24px] px-[32px]">
            {Slides.map((image, index) => {
              return (
                <div
                  className={` w-[10px] h-[10px] md:w-[12px] md:h-[12px] rounded-full 
                  
                      cursor-pointer hover:bg-[#6e08f3] ${
                        currentSlide == index + 1
                          ? " border-[#6e08f3] border-2 bg-[#6e08f3] "
                          : "border-[#6e08f3] border-2 "
                      }`}
                  onClick={(swiper) => {
                    setCurrentSlide(index + 1);
                    swiperInstance.slideTo(index);
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      </Swiper>
    </div>
  );
};

{
  /* <div className="slider-controler"> */
}
{
  /* <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </div> */
}
{
  /* <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div> */
}
{
  /* <div className=" pagination-container swiper-number flex items-center text-[#808191] text-[14px]">
                <p className="swiper-pagination flex "></p>
                <p className="hidden lg:block">
                  Showing {currentSlide}/{Slides.length}
                </p>
              </div> */
}
{
  /* </div> */
}
{
  /* custom pagination */
}

// <Swiper
// // effect={"coverflow"}

// // autoplay={true}
// // delay={1000}
// grabCursor={true}
// // centeredSlides={true}
// loop={true}
// // coverflowEffect={{
// //   rotate: 0,
// //   stretch: 0,
// //   depth: 100,
// //   modifier: 2.5,
// // }}
// pagination={{ el: "#custom-pagination", clickable: true }}
// breakpoints={{
//   // when window width is <= 640px
//   300: {
//     slidesPerView: 1,
//     spaceBetween: 10,
//   },
//   400: {
//     slidesPerView: 1,
//     spaceBetween: 10,
//   },
//   450: {
//     slidesPerView: 1.5,
//     spaceBetween: 15,
//   },
//   800: {
//     slidesPerView: 2,
//     spaceBetween: 20,
//   },
//   // when window width is <= 1024px
//   1024: {
//     slidesPerView: 2.5,
//     spaceBetween: 30,
//   },
// }}
// // navigation={{
// //   nextEl: ".swiper-button-next",
// //   prevEl: ".swiper-button-prev",
// //   clickable: true,
// // }}
